* {
  border-radius: 0px !important;
}

body {
  background-color: white;
  font-family: 'Source Sans Pro';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper-top {
  background-image: url('./images/tuned_02.jpg');
  
  background-color: #f5f5f5;
  padding-bottom: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
}

.top-title {
  font-size: 13px;
  color: #C12127;
  font-weight: bold;
}


.navbar-brand {
  margin-left: -1rem;
}

textarea {
  resize: none;
  overflow: hidden;
}

.logotype {
  width: 100px;
  height: auto;
}

.single-page {
  margin-bottom: 150px;
}

.single-page h1 {
  margin-bottom: 0.5em;
}

.single-page h2 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.preview-text {
  text-align: center;
  /*margin-bottom: 60px;*/
}

.preview-title {
  font-size: 20px;
  color: white;
  padding: 20px;
  /*margin-bottom: 15px;*/
}

.preview-title a {
color: white;
}

.preview-byline {
  color: gray;
  display: none;
}

.review-and-customize select:not(:last-child) {
  margin-right: 10px;
}

.file-contents-wrapper {
  /* width: 100%; */
  /* height: auto; */
  min-height: 300px;
  border: 1px solid;
  background-color: white;
  padding: 20px;
  margin-top: 15px;
  border-color: #ccc;
}

.file-contents-wrapper pre {
  margin: 0;
}

#file-contents {
  overflow: auto;
}

.enter-url {
  margin-top: 80px;
  margin-bottom: 30px;
}

.enter-url .btn-secondary {
  min-width: 150px;
  border-color: #C12127;
}

.enter-url .btn-secondary {
  background-color: #C12127;
  color: white;
}

.enter-url .btn-secondary:hover {
  background-color: #C12127;
  color: white;
  border-color: #C12127;
}

#maybe-track {
visibility: hidden;
}

.snippet .btn-secondary {
  min-width: 150px;
}

.snippet .btn-secondary:hover {
  border-color: #C12127;
}

.snippet .input-group-addon:hover:last-child {
  border-left: 1px solid #C12127;
}

.snippet .checkbox {
  font-size: 14px;
  margin-top: 15px;
  text-align: left;
}

#style-select {
  width: 150px;
}

footer {
  border-top: 1px solid whitesmoke;
  height: 300px;
  background-color: #C12127;
  color: white;
  font-size: 13px;
}

footer li {
list-style: none;
}

footer a {
color: white;
}

footer a:hover {
color: white;
}

.footer-inner {
  margin-top: 2rem;
}

.social a {
  text-decoration: none;
}

.social img {
  width: 32px;
  height: auto;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

/* Skin colors */

.tooltip-inner {
  background-color: #C12127;
}

.tooltip.tooltip-bottom .tooltip-arrow, 
.tooltip.bs-tether-element-attached-top .tooltip-arrow {
  border-bottom-color: #C12127;
}

.ge .form-control:focus {
  border-color: #C12127;
}



.c-select:focus {
  border-color: #C12127;
}





.wrapper-mid {
  background-color: #C12127;
}

.wrapper-bottom {
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: #fbfbfb;
}

.options select {
  min-width: 175px;
  margin-bottom: 10px;
}



.red {
  border-color: #C12127 !important;
}

.remove-red {
  transition-property: border-color;
  transition-duration: 0.7s;
  border-color: #ccc;
}

.bar {
  background-color: #C12127;
}

/* These styles are generated from project.scss. */

.alert-debug {
  color: black;
  background-color: white;
  border-color: #d6e9c6;
}

.alert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

/* This is a fix for the bootstrap4 alpha release */
@media (max-width: 47.9em) {
  .navbar-nav .nav-item {
    float: none;
    width: 100%;
    display: inline-block;
  }

  .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }

  .nav.navbar-nav.pull-xs-right {
    float: none !important;
  }
}

/* Display django-debug-toolbar.
 See https://github.com/django-debug-toolbar/django-debug-toolbar/issues/742
 and https://github.com/pydanny/cookiecutter-django/issues/317
*/
[hidden][style="display: block;"] {
display: block !important;
}


.width-300 {
  width: 300px;
}

.width-200 {
  width: 200px;
}

.options-container {
  float: right;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 20px;
}
 
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
